<template>
  <div class="index">
    <div class="xx index-1" :style="style">
      <div>
        <div
          :style="{ backgroundImage: 'url(' + image + ')' }"
          class="bg index-2"
        >
          <!-- title -->
          <div class="name-cs">
            {{ name_1.classify_name }}
          </div>
          <!-- 价格+图片 -->
          <div class="name_red">
            <div
              :style="{ backgroundImage: 'url(' + img + ')' }"
              class="bg-1 w-900px"
            >
              <!-- animate__animated animate__heartBeat -->
              <div class="text-200px">
                {{ name_1.real_money ? parseFloat(name_1.real_money) : '' }}
              </div>
              <!--  -->
            </div>
          </div>
          <!-- 二维码 -->
          <div class="code">
            <img class="code_img" :src="url" alt="" />
          </div>
          <!-- 倒计时 -->
          <!-- (countdown_time * 1000) - (Date.parse(new Date())) -->
          <div class="djs" v-if="countdown_time">
            <van-count-down
              ref="countDown"
              @finish="finish"
              :time="countdown_time * 1000 - Date.parse(new Date())"
            >
              <template #default="timeData">
                <span class="name_yellow"
                  >{{ timeData.minutes }}分钟{{
                    timeData.seconds
                  }}秒后价格更新</span
                >
              </template>
            </van-count-down>
          </div>
          <!-- 地址 -->
          <div class="dz name_yellow">地点：{{ store_address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const width = 1080
const height = 1920
export default {
  props: {
    sys: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      time: 0,
      image: require('@/assets/222.jpg'),
      img: require('@/assets/3333.png'),
      info: {},
      style: {
        width: width + 'px',
        height: height + 'px',
        transform: 'scale(1) translate(-50%,-50%)',
      },
    }
  },
  computed: {
    store_name() {
      return this.sys?.store_name ?? ''
    },
    store_address() {
      return this.sys?.store_address ?? ''
    },
    classify() {
      return this.info?.classify ?? []
    },
    name_1() {
      return this.classify[0] ?? {}
    },
    url() {
      return this.info?.code_url ?? ''
    },
    countdown_time() {
      return this.info?.countdown_time ?? 0
    },
  },
  mounted() {
    document.title = this.store_name
    window.onresize = this.reSize
    this.reSize()
    this.getDate()
    this.val = setInterval(() => {
      this.getDate()
    }, 60 * 1000)
  },
  destroyed() {
    clearInterval(this.val)
  },
  methods: {
    getScale() {
      const w = window.innerWidth / width
      const h = window.innerHeight / height
      return w - h > 0 ? h : w
    },
    reSize() {
      this.style.transform = `scale(${this.getScale()}) translate(-50%,-50%)`
    },
    async getDate() {
      let { data } = await this.$api.post('Index/getH5Home')
      this.info = data
    },
    finish() {
      this.$refs.countDown.reset()
      this.getDate()
    },
  },
}
</script>

<style scoped>

.xx {
  transform-origin: 0% 0%;
  position: absolute;
  left: 50%;
  top: 50%;
}

.bg .bg-1 {
  background-size: 100% 100%;
}
.animate__animated.animate__heartBeat {
  --animate-duration: 6s;
  animation-iteration-count: infinite;
}
.name-cs {
  color: #fffd0c;
  font-size: 280px;
  position: absolute;
  top: 250px;
  text-align: center;
  width: 100%;
}
.name_red {
  color: #ff0000;
  top: 680px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
}
.name_yellow {
  color: #fcfc0b;
  font-size: 60px;
}
.index {
  position: relative;
  height: 100vh;
  background: #000;
  overflow: hidden;
}
.index-1 {
  margin: 0 auto;
  overflow: hidden;
}
.index-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.w-900px {
  width: 900px;
  text-align: center;
  height: 450px;
}
.text-200px {
  font-size: 200px;
  line-height: 460px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 130px;
}
.code {
  top: 1260px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.code_img {
  width: 462px;
  height: 462px;
  object-fit: cover;
}
.djs {
  position: absolute;
  top: 1160px;
  width: 100%;
  text-align: center;
}
.dz {
  position: absolute;
  top: 1770px;
  width: 100%;
  text-align: center;
  font-size: 60px;
}

</style>
